import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import { ButtonDatePicker } from 'react-ui-kit-exante';

import { FILTERS_DATE_FORMAT } from '../../constants/date';

export const DateRangePicker: FC<{
  className?: string;
  onChangeStartDate: (date: Date | null) => void;
  onChangeEndDate: (date: Date | null) => void;
}> = ({ className, onChangeStartDate, onChangeEndDate }) => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [firstFieldIsOpened, setFirstFieldIsOpened] = useState(false);
  const [secondFieldIsOpened, setSecondFieldIsOpened] = useState(false);
  const onChangeFirstDate = (
    value: Date | [Date | null, Date | null] | null,
  ) => {
    if (Array.isArray(value)) {
      const [start] = value;
      setStartDate(start);
      if (!endDate) {
        setEndDate(start);
      }
      setFirstFieldIsOpened(false);
      if (secondFieldIsOpened) {
        setSecondFieldIsOpened(false);
      }
      setSecondFieldIsOpened(true);
    }
  };
  const onChangeSecondDate = (
    value: Date | [Date | null, Date | null] | null,
  ) => {
    if (Array.isArray(value)) {
      const [start] = value;
      setEndDate(start);
      setSecondFieldIsOpened(false);
    }
  };

  useEffect(() => {
    onChangeStartDate(startDate);
  }, [startDate]);
  useEffect(() => {
    onChangeEndDate(endDate);
  }, [endDate]);

  return (
    <div
      className={classNames(
        'd-flex justify-content-end align-items-center',
        className,
      )}
    >
      <ButtonDatePicker
        open={firstFieldIsOpened}
        selectsRange
        onChange={onChangeFirstDate}
        onInputClick={() => {
          setFirstFieldIsOpened(true);
        }}
        onClickOutside={() => setFirstFieldIsOpened(false)}
        startDate={startDate}
        endDate={endDate}
      />
      <div>{dayjs(startDate).format(FILTERS_DATE_FORMAT)}</div>
      <ButtonDatePicker
        open={secondFieldIsOpened}
        selectsRange
        onInputClick={() => {
          setSecondFieldIsOpened(true);
        }}
        onClickOutside={() => setSecondFieldIsOpened(false)}
        onChange={onChangeSecondDate}
        startDate={startDate}
        endDate={endDate}
      />
      <div className="ml-1">{dayjs(endDate).format(FILTERS_DATE_FORMAT)}</div>
    </div>
  );
};
