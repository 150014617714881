import dayjs from 'dayjs';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  calculateCountOfPages,
  IOnFetchArguments,
  ISortBy,
  Notification,
  OnSaveEditableRow,
  Table,
  useTableData,
} from 'react-ui-kit-exante';

import { DownloadIcon } from 'components';
import { defaultLocale } from 'constants/app';
import { DATE_FORMAT } from 'constants/date';
import { DEFAULT_FILTER_VALUES, pageSizes } from 'constants/tables';
import { useAppSelector, usePropSelector } from 'hooks/redux';
import {
  CpAndCTrade,
  PatchCpAndCTradePayload,
  SIDE,
  getCTrades,
  patchCpAndCTrade,
  getCTradesUrl,
} from 'services/recon';
import { ReconApiService } from 'services/recon/api';
import {
  cpListByEntitySelector,
  modeNamesSelector,
  newLegalEntityNamesForBroker,
} from 'store/reducers/commonReducer';
import { clickToLinkForFetchingData } from 'utils';
import { getChangedValues } from 'utils/getChangedValues';
import { getDefaultFilterParams } from 'utils/getDefaultFilterParams';
import { getSelectOptions } from 'utils/getSelectOptions';

import { CTradesTable as CTradesTableType } from './types';
import { getAdditionalFilters, getColumns } from './utils/getColumns';

const tableId = 'CTradesTable';
export const CTrades = () => {
  const leNames = useAppSelector(newLegalEntityNamesForBroker);
  const modes = useAppSelector(modeNamesSelector);
  const ccyList = useAppSelector((state) => state.currencies);

  const entityOptions = useMemo(() => getSelectOptions(leNames), [leNames]);
  const modeOptions = useMemo(() => getSelectOptions(modes), [modes]);
  const ccyOptions = useMemo(() => getSelectOptions(ccyList), [ccyList]);

  const filtersIsFirstMounted = useRef(false);
  const [excelIsDownloading, setExcelIsDownloading] = useState(false);

  const getTrades = useCallback(
    (params: IOnFetchArguments) => getCTrades(params),
    [],
  );

  const applyObject = {
    date: [dayjs().subtract(7, 'day').toDate(), dayjs().toDate()],
    counterparty: DEFAULT_FILTER_VALUES.cp,
    mode: DEFAULT_FILTER_VALUES.mode,
  };
  const filterKeys = getColumns({}).map((column) => column.accessor);
  const prepareFiltersForParams = useCallback(
    getDefaultFilterParams({
      filterKeys,
      applyObject,
      isApply: filtersIsFirstMounted,
      shouldApply: !window.location.search,
    }),
    [],
  );
  const tableData = useMemo(
    () => ({
      tableId,
      data: { onFetch: getTrades },
      saveViewParamsAfterLeave: true,
      filters: {
        prepareFiltersForParams,
      },
      pagination: { getDefaultPagination: () => ({ limit: 10, skip: 0 }) },
    }),
    [getTrades],
  );
  const {
    data,
    resetFilters,
    filters,
    setFilter,
    removeFilter,
    setSorting,
    page,
    setPage,
    limit,
    setLimit,
    isLoading,
    fetchData,
    sorting,
    params,
  } = useTableData<CTradesTableType>(tableData);

  const total = data?.pagination?.total || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const cpListByEntity = usePropSelector(
    cpListByEntitySelector,
    (filters.le as string) ?? DEFAULT_FILTER_VALUES.le,
  );
  const cpOptions = useMemo(
    () => getSelectOptions(cpListByEntity?.map((item) => item.name) ?? []),
    [cpListByEntity],
  );
  useEffect(() => {
    if (cpOptions.length && filters.counterparty) {
      const foundCp = cpOptions.find(
        (item) => item.value === filters.counterparty,
      );
      if (!foundCp) {
        setFilter('counterparty', cpOptions[0].value);
      }
    }
  }, [cpOptions]);
  useEffect(() => {
    if (leNames.length && !filters.le) {
      setFilter('le', leNames[0]);
    }
  }, [leNames]);

  const [selectedTrades, setSelectedTrades] = useState<CpAndCTrade[]>([]);
  const handleCheckAllOnPage = () => {
    const notSelectedOnPage = data?.trades.filter(
      (i) => !selectedTrades.find((j) => j.id === i.id),
    );
    setSelectedTrades([...selectedTrades, ...(notSelectedOnPage || [])]);
  };
  const handleUnCheckAllOnPage = () => {
    const withoutTradesOnPage = selectedTrades.filter((item) => {
      const foundTrade = data?.trades.find((i) => i.id === item.id);
      return !foundTrade;
    });
    setSelectedTrades(withoutTradesOnPage);
  };
  const handleCheckTrade = useCallback(
    (trade: CpAndCTrade) => {
      if (selectedTrades.find((i) => i.id === trade.id)) {
        setSelectedTrades([...selectedTrades.filter((i) => i.id !== trade.id)]);
      } else {
        setSelectedTrades([...selectedTrades, trade]);
      }
    },
    [setSelectedTrades, selectedTrades],
  );

  const columns = useMemo(
    () =>
      getColumns({
        trades: data?.trades,
        selectedTradesIds: selectedTrades.map((i) => i.id),
        onCheckTrade: handleCheckTrade,
        onCheckAllTradesOnPage: handleCheckAllOnPage,
        onUnCheckAllTradesOnPage: handleUnCheckAllOnPage,
        onFilter: setFilter,
        onRemove: removeFilter,
        cpOptions,
        ccyOptions,
        modeOptions,
      }),
    [data, selectedTrades, cpOptions, ccyOptions, modeOptions],
  );
  const additionalFilters = useMemo(
    () =>
      getAdditionalFilters({
        onFilter: setFilter,
        onRemove: removeFilter,
        entityOptions,
        cpOptions,
      }),
    [setFilter, removeFilter, entityOptions, cpOptions],
  );
  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
      additionalFilters,
    }),
    [filters, resetFilters, additionalFilters],
  );
  const handleSaveEditableRow: OnSaveEditableRow<CpAndCTrade> = async (
    previousValues,
    updatedValues,
  ) => {
    try {
      const changedValues = getChangedValues(previousValues, updatedValues);
      const payload: PatchCpAndCTradePayload = {
        id:
          selectedTrades.length > 1
            ? selectedTrades.map((item) => item.id).join(',')
            : previousValues.id,
        side: SIDE.OUR,
      };
      if (changedValues.date) {
        payload.date = dayjs(changedValues.date).format(DATE_FORMAT);
      }
      if (changedValues.cpname) {
        payload.cp = changedValues.cpname;
      }
      await patchCpAndCTrade(payload);
      Notification.success({ title: 'Trade was updated' });
      setSelectedTrades([]);
      fetchData();
    } catch (e) {
      if (String(e).includes('unique')) {
        Notification.error({
          title: 'Trade already exists',
        });
      } else {
        Notification.error({ title: 'Update trade error' });
      }
    }
  };
  const handleSorting = useCallback(
    (sortingArray: ISortBy[]) => {
      setSorting(sortingArray);
    },
    [setSorting],
  );

  const fetchExcelFile = useCallback(async () => {
    try {
      const url = getCTradesUrl({
        params,
        filtersParams: { ...filters, generate_report: true },
        sortingParams: sorting,
        paginationParams: {
          page: params.page,
          skip: params.skip,
          limit: params.limit,
        },
      });
      setExcelIsDownloading(true);
      const response = await fetch(ReconApiService.apiBase + url.url, {
        headers: ReconApiService.getBaseHeaders(),
      });
      if (!response.ok) {
        const error = await response.json();
        throw new Error(JSON.stringify(error));
      }
      setExcelIsDownloading(false);
      await clickToLinkForFetchingData(
        response,
        `${filters.le}_CTrades_report_${url.dates.fromDate}_${url.dates.toDate}`,
      );
    } catch (error) {
      Notification.error({
        title: 'Loading file error',
        description: String(error),
      });
    } finally {
      setExcelIsDownloading(false);
    }
  }, [filters, params]);

  const serverPaginationProps = {
    pageIndex: page,
    pageCount,
    pageSize: limit,
    total,
    setPage,
    setPageSize: setLimit,
  };

  return (
    <Table
      tableId={tableId}
      title="Our Trades"
      manualSortBy
      showTableInfo
      isFlexLayout
      hasPagination
      hasFilters
      saveViewParamsAfterLeave
      showScrollbar
      locale={defaultLocale}
      isLoading={isLoading}
      data={data?.trades ?? []}
      columns={columns}
      pageSizes={pageSizes}
      serverPaginationProps={serverPaginationProps}
      filteringProps={filteringProps}
      onSort={handleSorting}
      defaultSortBy={[{ id: 'id', desc: true }]}
      additionalActions={[
        {
          title: 'Load Excel',
          component: (
            <DownloadIcon
              isLoading={excelIsDownloading}
              onClick={fetchExcelFile}
            />
          ),
        },
      ]}
      rowActions={{
        show: true,
        onSave: handleSaveEditableRow,
      }}
    />
  );
};
